@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

/* Map */
.mapboxgl-ctrl.mapboxgl-ctrl-group {
  display: inline-block;
}

/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  position: relative;
  width: 100%;
  min-width: 240px;
  z-index: 2;
  transition: width 0.25s, min-width 0.25s;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none; /* hide input clear button in IE */
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
  right: 8px;
  top: 7px;
  display: none;
}

.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width 0.25s, min-width 0.25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  background-color: $white;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
  &.placement-top {
    top: 110%; /* fallback */
    top: calc(100% + 6px);
  }
  &.placement-bottom {
    bottom: 110%; /* fallback */
    bottom: calc(100% + 6px);
  }
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
  z-index: 1;
}

.mapboxgl-ctrl-geocoder .suggestions > li {
  cursor: default;
  display: block;
  padding: 6px 12px;
  color: $gray-700;
}

.mapboxgl-ctrl-geocoder .suggestions > li + li {
  border-top: solid 1px $gray-200;
}

.mapboxgl-ctrl-geocoder .suggestions > li + li.new-suggestion {
  background-color: $gray-100 !important;
}

.mapboxgl-ctrl-geocoder .suggestions > li.active,
.mapboxgl-ctrl-geocoder .suggestions > li:hover {
  color: $gray-700;
  background-color: $gray-100;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  fill: $gray-600;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 13px;
  left: 12px;
  width: 23px;
  height: 23px;
}

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: $white;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: $gray-500;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media queries*/
@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 8px;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    left: 7px;
    width: 20px;
    height: 20px;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .mapbox-gl-geocoder--error {
    color: $gray-500;
    padding: 6px 12px;
    font-size: 16px;
    text-align: center;
  }
}
