@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./forms";

html {
  height: 100%;
}
body {
  height: 100%;
}

.question-btn {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-size: 2rem;
  width: 48px;
  height: 48px;
  line-height: 24px;
  border-radius: 50%;
  color: $primary;
}

textarea {
  border-radius: 0.25rem !important;
  min-height: 2rem;
}

textarea.citrus-feedback {
  border-radius: 0.25rem !important;
  min-height: 14rem;
  max-height: 14rem;
  resize: none;
}

.fade-in-right {
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  @media (min-height: 35rem) {
    .payment-container {
      .order-md-0 + .order-md-1 {
        border-left: 1px solid $light !important;
      }
    }
    .card-body-overflow-md {
      overflow-y: scroll;
      max-height: 32vh;
    }
  }
}
