@import "./variables";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@include media-breakpoint-only(lg) {
  .event-content {
    .event-title {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  header.event-header {
    min-height: unset;
    height: unset;
    background: unset;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .event-blurhash,
  .event-banner {
    min-height: 50vw;
    img {
      max-height: 50vw;
    }
  }
  .event-blurhash {
    max-height: 50vw;
    overflow: hidden;
  }
  header.event-header.without-image {
    min-height: unset;
    height: 16rem;
    position: unset;
    background-color: unset;
    + .event-content {
      .event-banner {
        margin-top: 0rem;
      }
    }
  }
  .header-row {
    margin-left: -15px !important;
    margin-right: -15px !important;
    padding-top: 0rem;
  }
}

@include media-breakpoint-up(lg) {
  header.event-header {
    overflow: hidden;
    .footer-fade {
      position: relative;
      width: 100%;
      height: 100%;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba($black, 0), rgba($white, 1) 90%);
        width: 100%;
        height: 4em;
      }
    }
    & + .event-content {
      margin-top: -18rem;
      background-color: $white !important;
      border-radius: 3.125rem !important;
      .header-row {
        border-bottom: 1px solid $light !important;
        .event-banner {
          border-top-left-radius: 3.125rem !important;
          margin-top: -4px;
          margin-left: -4px;
          margin-right: -4px;
          overflow: hidden;
          min-height: 20rem;
          .event-blurhash {
            overflow: hidden;
            max-height: 24rem;
            min-height: 20rem;
          }
          img {
            max-height: 24rem;
          }
        }
        .event-title {
          border-top-right-radius: 3.125rem !important;
          margin-top: -4px;
          overflow: hidden;
          background-color: $white;
        }
      }
    }
  }
  .w-md-25 {
    width: 25%;
  }
  .description {
    min-height: 185px;

    a {
      font-weight: 700 !important;
    }
  }
}
