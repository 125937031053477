@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

header {
  width: 100%;
  height: 23rem;
}

.fade-arrow {
  margin: calc(1rem + 0.375rem) 1rem;
}

@include media-breakpoint-down(md) {
  .fade-arrow {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
  }
}

@include media-breakpoint-up(md) {
  @media (min-height: 35rem) {
    header {
      height: 27rem;
    }
    .fade-arrow {
      padding: 0.5rem 2rem !important;
      text-transform: unset;
      font-weight: 400 !important;
      span {
        display: inline-block;
        position: relative;
        transition: all 300ms ease-out;
        will-change: transform;
      }
      &:hover span {
        transform: translate3d(1rem, 0, 0);
      }
      svg {
        position: absolute;
        width: 1.1em;
        left: 0px;
        left: 0rem;
        opacity: 0;
        top: 50%;
        transform: translateY(-60%);
        transition: all 300ms ease-out;
        will-change: right, opacity;
        * {
          stroke-width: 5;
          stroke: transparent;
        }
      }
      &:hover svg {
        opacity: 1;
        left: -1.5rem;
      }
    }
  }
}

/* React Select -- Inline Modifier
* Inline container with margin for better inline spacing between other elements 
*/
.react-select--inline {
  display: inline-block;
  margin: 0 0.25em;
}

/* Remove border, outline, box-shadow, and min-height values */
.react-select--inline .react-select__control {
  outline: none;
  box-shadow: none;
  min-height: none;
  cursor: pointer;
}

/* Tighten up spacing */
.react-select--inline .react-select__value-container {
  padding-right: 0;
}

/* Position value relative (instead of absolute) and remove transform and max-width */
.react-select--inline .react-select__single-value,
.react-select--inline .react-select__placeholder {
  font-weight: bold;
  position: relative;
  transform: none;
  max-width: none;
}
