@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.form-label {
  color: $gray-600;
  font-size: 80%;
  font-weight: 400;
  margin: 0;
}

// Floating Labels
.form-label-group {
  position: relative;
  margin-bottom: 1rem;
  background: "green";
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.form-label-group input:-webkit-autofill {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:-webkit-autofill ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

// Optional
label.optional::after {
  content: "(Optional)";
  color: $gray-600;
  display: inline;
  margin-left: 0.5rem;
  font-style: italic;
  font-size: 80%;
  font-weight: 400;
}

.react-tel-input {
  .form-control {
    font-size: $input-font-size !important;
    background: $input-bg !important;
    border: $input-border-width solid $input-border-color !important;
    border-radius: $input-border-radius !important;
    width: 100% !important;
    padding: $input-padding-y $input-padding-x $input-padding-y 3.75rem !important;
    color: $input-color !important;
  }
  .form-control:invalid,
  .form-control.is-invalid {
    border-color: $danger !important;
  }
}

.custom-select {
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  font-weight: bold;
}

.custom-control {
  z-index: 0 !important;
}

// jumbo-buttons

.card-select {
  .card-body {
    padding: 1.25rem;
  }
  .custom-control-label .form-text {
    color: $gray-600;
  }

  &.selected {
    background-color: lighten($primary, 0.1);
    color: $white;
    .custom-control-label .form-text {
      color: $light;
    }
  }

  &.disabled,
  &.disabled:hover {
    cursor: default;
    background-color: $light;
    color: $white;
    &::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    &::-moz-selection {
      color: none;
      background: none;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .custom-control-label {
    cursor: pointer;

    *::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    *::-moz-selection {
      color: none;
      background: none;
    }
  }

  &.disabled .custom-control-label * {
    cursor: default;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    display: none !important;
  }

  .custom-control-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.625rem;
    height: 1.25rem;
    width: 1.25rem;
    left: -1.5rem;
    display: block;
    pointer-events: "none";
  }
}

// Other

.invalid-feedback {
  padding: 0 0.75rem;
}

.form-radio-buttons + .invalid-feedback {
  padding: 0;
}

// react-phone-input-2

.react-tel-input {
  .form-control {
    font-size: $input-font-size !important;
    background: $input-bg !important;
    border: $input-border-width solid $input-border-color !important;
    border-radius: $input-border-radius !important;
    width: 100% !important;
    padding: $input-padding-y $input-padding-x $input-padding-y 3.75rem !important;
    color: $input-color !important;
  }
  .form-control:invalid,
  .form-control.is-invalid {
    border-color: $danger !important;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: $input-disabled-bg !important;
    opacity: 1;
  }
  .flag-dropdown,
  .flag-dropdown.open,
  .selected-flag,
  .selected-flag:before,
  .flag-dropdown.open .selected-flag {
    border-radius: $input-border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .country-list .flag {
    position: unset;
  }
}
