@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

body {
  background: $light;
}

.custom-branding {
  display: none;
}

main {
  min-height: 90vh;
}

.text-decorate-underline {
  text-decoration: underline !important;
}

.rounded-xs {
  border-radius: 0.25rem !important;
}

img {
  color: transparent !important;
}

img[alt]:-moz-broken {
  display: none;
}

.row-img-circle {
  display: block;
  width: 40px;
  height: 40px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: auto;
    height: 100%;
    margin-top: 50%;
    margin-left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

.expand-text {
  word-break: break-word !important;
  word-wrap: break-word !important;
  &:not(.show) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.event-card,
.program-card,
.card-select,
.package-card,
.appointment-card {
  transition: all 0.3s;
}

.event-card:hover,
.program-card:hover,
.card-select:hover,
.package-card:hover,
.appointment-card:hover {
  transform: scale(1.05);
}

.event-card .card-img-top .card-img,
.event-card .card-img-top,
.program-card .card-img-top {
  background-color: $gray;
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  height: 165px;
  display: block;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  transition: max-height 1s ease;
  overflow: hidden;
}

.event-card .card-img-top.hidden,
.program-card .card-img-top.hidden {
  max-height: 0;
  .price-tag {
    display: none;
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  @for $i from 1 through 6 {
    .text-truncate-#{$i} {
      &:not(.show) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: $i;
        -webkit-box-orient: vertical;
        white-space: initial;
        overflow-wrap: break-word;
        word-break: break-word;
        &.fixed {
          min-height: calc($line-height-sm * $i * 1rem);
        }
        // &.break-normal {
        //   overflow-wrap: normal !important;
        //   word-break: normal !important;
        // }
        // &.break-word {
        //   overflow-wrap: break-word !important;
        //   word-break: break-word !important;
        // }
        // &.break-all {
        //   overflow-wrap: break-all !important;
        //   word-break: break-all !important;
        // }
      }
    }
  }
}

.auth-container {
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
}

@include media-breakpoint-up(md) {
  .auth-container {
    background-color: $light !important;
    .card-body {
      padding: 2.5rem;
    }
  }
  .auth-logo {
    bottom: 2rem;
    left: 2rem;
    position: absolute;
  }
}

.auth-container {
  top: 0;
  overflow-y: scroll;
  background-attachment: fixed;
  .auth-content {
    position: absolute;
    width: 100%;
    min-height: 100vh;
  }

  .container {
    width: 100vw;
    min-height: calc(100vh - 88px);
    display: flex;
    justify-content: center !important;
    > div {
      width: 100%;
      min-height: 100%;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 4rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .auth-container {
    // overflow: hidden;
    .auth-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      footer {
        position: relative;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;
}

.avatar:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 0;
  background-color: #b1c2d9;
  color: #fff;
}

.avatar-offline::before,
.avatar-online::before {
  content: "";
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border-radius: 50%;
}

.avatar-offline .avatar-img,
.avatar-online .avatar-img {
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.avatar-online::before {
  background-color: $success;
}

.avatar-offline::before {
  background-color: $gray;
}

.avatar-xs {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.54167rem;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.83333rem;
}

.avatar-lg {
  width: 4rem;
  height: 4rem;
  font-size: 1.33333rem;
}

.avatar-xl {
  width: 5.125rem;
  height: 5.125rem;
  font-size: 1.70833rem;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
  font-size: 1.70833rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
    font-size: 2.66667rem;
  }
}

.dropdown-toggle::after {
  display: none;
}

.navbar-dark {
  .btn-account {
    color: #fff;
    background-color: rgba($dark, 0.75);
    border-color: $dark;
    font-weight: 400;
  }

  .btn-account:hover {
    color: #fff;
    background-color: rgba(lighten($dark, 15%), 0.9);
  }
}
.auth-container,
.navbar-light {
  .btn-account {
    color: #000;
    background-color: rgba($gray-200, 0.75);
    border-color: $gray-200;
    font-weight: 400;
  }

  .btn-account:hover {
    color: #000;
    background-color: rgba(darken($gray-200, 15%), 0.9);
  }
}

.avatar.avatar-4by3 {
  width: 4rem;
}

.avatar-xs.avatar-4by3 {
  width: 2.16667rem;
}

.avatar-sm.avatar-4by3 {
  width: 3.33333rem;
}

.avatar-lg.avatar-4by3 {
  width: 5.33333rem;
}

.avatar-xl.avatar-4by3 {
  width: 6.83333rem;
}

.avatar-xxl.avatar-4by3 {
  width: 10.66667rem;
}

.avatar-group {
  display: -webkit-inline-box;
  display: inline-flex;
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -2rem;
}

.avatar-group .avatar:not(:last-child) {
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.avatar-group .avatar:hover {
  -webkit-mask-image: none;
  mask-image: none;
  z-index: 1;
}

.avatar-group .avatar:hover + .avatar {
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.popover .dropdown-item {
  padding: 0.75rem 2rem;
  border-radius: 0.3rem;
}

.dropdown-item .avatar-sm {
  width: 3.5rem;
  height: 3.5rem;
}

.btn.btn-link {
  text-transform: unset;
  border-radius: 0;
}

.scrollable-text {
  position: relative;
  border: $border-width solid $gray-400;
  background-color: $gray-100;
  border-radius: $textarea-border-radius;
  max-height: 5rem;
  overflow-y: scroll;
  padding: 0.5rem;
  font-size: 85%;
}

.scrollable-container {
  overflow-x: scroll;
  > .row {
    flex-wrap: nowrap;
  }
}

// Utilities
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}-#{$breakpoint}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
    .scrollable-#{$breakpoint}-container {
      overflow-x: scroll;
      > .row {
        flex-wrap: nowrap;
      }
    }
  }
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

// Skeleton

.shimmer {
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100% !important;
  min-height: 1rem;
}

$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size
) !default;

@mixin make-heading-skeletons {
  @each $heading, $value in $heading-sizes {
    #{$heading}.animate,
    .#{$heading}.animate {
      min-height: $value !important;
    }
  }
}
@include make-heading-skeletons();

.nav-tabs .nav-link.animate {
  min-width: 100px !important;
}

.card.animate {
  min-height: 200px !important;
}

.form-control.animate,
.card.animate {
  border: 1px solid #e3ebf6 !important;
}

.btn.animate {
  min-height: $btn-padding-y + $btn-padding-y + $btn-line-height;
  min-width: 125px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.search-input,
.search-input:focus {
  border-width: 0px 0px 2px 0px !important;
  border-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none !important;
}

@include media-breakpoint-down(sm) {
  .search-input + .input-group-append > .btn-outline-primary {
    border: 0 !important;
  }

  .navbar-header .navbar-nav .active > .nav-link,
  .navbar-header .navbar-nav .nav-link.active {
    font-weight: bold;
  }
  .navbar-header .btn-back {
    display: flex;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
  }
  .auth-container {
    .navbar-header.navbar-dark .navbar-toggler {
      color: $black;
    }
  }
}

.navbar-header.navbar-light .navbar-nav .show > .nav-link,
.navbar-header.navbar-light .navbar-nav .active > .nav-link,
.navbar-header.navbar-light .navbar-nav .nav-link.show,
.navbar-header.navbar-light .navbar-nav .nav-link.active,
.navbar-header.navbar-light .navbar-nav .nav-link {
  color: $black;
}
.navbar-header.navbar-dark .navbar-nav .show > .nav-link,
.navbar-header.navbar-dark .navbar-nav .active > .nav-link,
.navbar-header.navbar-dark .navbar-nav .nav-link.show,
.navbar-header.navbar-dark .navbar-nav .nav-link.active,
.navbar-header.navbar-dark .navbar-nav .nav-link {
  color: $white;
}

@include media-breakpoint-up(md) {
  @media (min-height: 35rem) {
    .auth-container {
      .navbar-header.navbar-dark .navbar-nav .show > .nav-link,
      .navbar-header.navbar-dark .navbar-nav .active > .nav-link,
      .navbar-header.navbar-dark .navbar-nav .nav-link.show,
      .navbar-header.navbar-dark .navbar-nav .nav-link.active,
      .navbar-header.navbar-dark .navbar-nav .nav-link {
        color: $black;
      }
    }
  }
}

// Custom FullCalendar
.custom-full-calendar {
  .fc .fc-daygrid-day.fc-day-today,
  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba($primary, 0.1);
  }
  .fc-daygrid-event-dot {
    border-color: $primary;
  }
  @each $color, $value in $theme-colors {
    .fc .fc-daygrid-day.fc-day-today.fc-day-#{$color},
    .fc .fc-timegrid-col.fc-day-today.fc-day-#{$color} {
      background-color: rgba($primary, 0.1);
    }
    .fc-daygrid-event-dot.fc-#{$color}-dot {
      border-color: $value;
    }
  }
  .fc-h-event .fc-event-main {
    color: $white;
    display: flex;
    overflow: hidden;
    .fc-daygrid-event-dot {
      display: none;
    }
    .fc-event-time {
      margin-left: 3px;
    }
  }
  .fc .fc-daygrid-event {
    overflow: hidden;
  }
  .fc-h-event {
    border-color: $white;
    background-color: $primary;
  }
  .fc-v-event {
    border-color: $white;
    background-color: $primary;
  }
  .fc .fc-scrollgrid {
    border: 0;
  }
  .table-bordered th,
  .table-bordered td {
    border-right-style: none !important;
  }
  .table-bordered td {
    border-bottom-style: none !important;
  }
  .fc-h-event .fc-event-time {
    max-width: unset;
    overflow: unset;
  }
  .fc-event {
    cursor: pointer;
  }
}

// Crop Modal

.crop-modal.rounded {
  .ReactCrop__crop-selection {
    border-radius: 50%;
    border-image-source: unset;
    &:focus {
      outline: none;
    }
    .ReactCrop__rule-of-thirds-hz::before,
    .ReactCrop__rule-of-thirds-hz::after {
      left: 5px;
      right: 5px;
    }
    .ReactCrop__rule-of-thirds-vt::before,
    .ReactCrop__rule-of-thirds-vt::after {
      width: 1px;
      top: 5px;
      bottom: 5px;
    }
  }
  .ReactCrop__drag-handle {
    border-radius: 50%;
  }
}

.editable-avatar {
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  vertical-align: middle;
  text-align: center;
  color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 10000;
  color: white;
}

.editable-avatar svg {
  display: inline-block;
  margin-top: 40%;
}

::-webkit-scrollbar {
  width: 0px;
}

@include media-breakpoint-up(lg) {
  .btn-date-link > svg {
    font-size: 2rem;
  }
}

.rating-button {
  &:hover {
    transform: scale(1.5);
  }
  transition: all 300ms;
}

.feedback-comments {
  display: block;
  width: 100%;
  min-height: 5rem;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.5rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

// Image
.image-container {
  width: 100%;
  height: 100%;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    position: relative !important;
    object-fit: cover; // Optional
  }
}

.custom-support {
  position: fixed;
  z-index: 1030;
  bottom: 0;
  right: 0;
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  max-width: 24rem;

  .btn-support {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
  .support-form {
    background-color: #ffff;
    border-radius: 1rem;
    border-bottom: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.ReactQueryDevtoolsPanel pre {
  color: unset;
}

.row-img-square {
  padding: "20px";
  background-color: addAlpha(#f5841f, 0.5);
  display: block;
  width: 100px;
  height: 100px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  .row-img {
    display: block;
    width: 60px;
    height: 60px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    color: "";
  }
}

.marker-pin {
  font-size: 2em;
}

/*
 * Home Page
 */

.citrus-header {
  width: 100%;
}

.wave-one {
  width: 100%;
  height: 52rem;
  background: linear-gradient(180deg, rgba($primary-60, 0.45), rgba(lighten($primary-60, 35%), 0.25));
  background-size: 100% 400%;

  -webkit-mask-image: url("/imgs/orange-bg-00.svg");
  mask-image: url("/imgs/orange-bg-00.svg");

  -webkit-mask-size: cover;
  mask-size: cover;

  -webkit-animation: GradientScroll 30s ease 60s;
  -moz-animation: GradientScroll 30s ease 60s;
  animation: GradientScroll 30s ease 60s;
}

@-webkit-keyframes GradientScroll {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes GradientScroll {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes GradientScroll {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.bg-error {
  animation: fadeIn80Animation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn80Animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

#error-page {
  animation: fadeIn100Animation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn100Animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Tooltip
.tooltip {
  display: inline-block;
  background: $dark;
  color: $white;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 0.5rem;
  z-index: 1080;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^="top"] > .arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .arrow {
  left: -4px;
}
