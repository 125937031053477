@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";

.card-select {
  .custom-control-label .form-text {
    color: $gray-600;
  }

  &.selected {
    background-color: lighten($primary, 0.1);
    color: $white;
    .custom-control-label .form-text {
      color: $light;
    }
  }

  &.disabled,
  &.disabled:hover {
    cursor: default;
    background-color: $light;
    color: $white;
    &::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    &::-moz-selection {
      color: none;
      background: none;
    }
    .badge {
      opacity: 0.7;
    }
  }

  @each $state, $data in $form-validation-states {
    @include form-validation-state-selector($state) {
      border-color: map-get($data, color);

      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-image: escape-svg(map-get($data, icon));
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  .custom-control-label {
    cursor: pointer;

    *::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    *::-moz-selection {
      color: none;
      background: none;
    }
  }

  &.disabled .custom-control-label * {
    cursor: default;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    display: none !important;
  }

  .custom-control-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.625rem;
    height: 1.25rem;
    width: 1.25rem;
    left: -1.5rem;
    display: block;
    pointer-events: "none";
  }
}
