@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.toaster {
  z-index: 2080;
  display: flex;
  position: fixed;
  top: 1.5rem;
  right: 0;
  .toast {
    width: 320px;
    .toast-header {
      padding: 1rem;
      border-bottom: none !important;
    }

    @each $color, $value in $theme-colors {
      &.toast-#{$color} .toast-icon {
        color: $value;
      }
      &.toast-#{$color} .progress-bar {
        background-color: $value;
      }
    }
    .progress {
      height: 3px !important;
      transform: rotate(180deg);
    }
    .progress-bar {
      -webkit-transition: width 0.5s ease;
      -o-transition: width 0.5s ease;
      transition: width 0.5s ease;
    }
    .toast-message {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
    .close {
      font-size: 1rem;
      margin-bottom: auto !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .toaster {
    top: unset;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    .toast {
      width: 100%;
    }
  }
}

.fade-out-right {
  -webkit-animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
