// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2c7be5;
$indigo: #727cf5;
$purple: #6b5eae;
$pink: #ff679b;
$red: #e63757;
$orange: #f6841f;
$yellow: #f6c343;
$green: #71bf44;
$teal: #02a8b5;
$cyan: #39afd1;
$gray: #8b97a8;
$gray-dark: #3b506c;
$primary: $gray-dark;
$secondary: $gray;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$light: #edf2f9;
$dark: #292e31;

$primary-60: #f8b578;
$primary-40: #facea5;
$warning-70: #f9d57b;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "primary-60": $primary-60,
    "primary-40": $primary-40,
    "warning-70": $warning-70,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "gray": $gray
  ),
  $theme-colors
);

// Body
//
// Settings for the `<body>` element.

$body-color: $dark !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 36rem,
  md: 48rem,
  lg: 62rem,
  xl: 75rem
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 33.75rem,
  md: 45rem,
  lg: 60rem,
  xl: 71.25rem
) !default;

// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 3.125rem !default;
$border-radius-lg: 3.25rem !default;
$border-radius-sm: 2.5rem !default;

$component-active-color: $white !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$headings-font-family: "Montserrat", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-weight: 700 !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-font-weight: 700 !default;
$btn-box-shadow:
  0 4px 6px rgba($gray-dark, 0.11),
  0 1px 3px rgba($dark, 0.08);

// Cards
$card-border-radius: 0.25rem !default;

// Forms
$custom-checkbox-indicator-border-radius: 0.25rem !default;
$form-group-margin-bottom: 0.5rem !default;
$input-disabled-bg: $gray-200 !default;

$textarea-border-radius: 1.25rem !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-select-indicator-color: $gray-800 !default;
// Form validation

$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;
$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: none !default; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: none !default; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

// Navs

$nav-link-padding-y: 1rem !default;
$nav-link-padding-x: 2rem !default;

$nav-tabs-border-radius: 0.25rem !default;

$navbar-light-color: rgba($black, 0.7) !default;
$navbar-light-hover-color: rgba($black, 0.9) !default;
$navbar-light-active-color: rgba($primary, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;

$navbar-dark-color: rgba($white, 0.5) !default;
$navbar-dark-toggler-icon-bg: none !default; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-icon-bg: none !default; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-min-width: 12.5rem !default;
$dropdown-border-radius: 0.25rem !default;

$dropdown-link-active-bg: $gray-300;

// Modals

$modal-inner-padding: 1.5rem !default;
$modal-content-border-radius: 0.3rem !default;
$modal-backdrop-bg: $white !default;
$modal-backdrop-opacity: 0.75 !default;

// List group

$list-group-border-radius: 0.25rem !default;

// Carousel

$carousel-control-color: $white !default;
$carousel-control-prev-icon-bg: none !default; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>");
$carousel-control-next-icon-bg: none !default; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");

// Popovers
$popover-border-radius: 0.3rem !default;
$popover-max-width: 320px !default;

// Utilities

$displays:
  none,
  inline,
  inline-block,
  block,
  table,
  table-row,
  table-cell,
  flex,
  inline-flex,
  -webkit-box !default;
