@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.account-container,
.order-container,
.register-container,
.checkout-container,
.register-container,
.attendee-container {
  // margin-top: auto !important;
  margin-bottom: auto !important;
  padding-top: 3.725rem;
  padding-bottom: 3.725rem;
}

.account-container,
.attendee-container {
  .nav-tabs {
    border-bottom: 1px solid $light;

    .nav-item {
      margin-bottom: -1px;
    }

    .nav-item:first-child {
      margin-left: 0;
    }

    .nav-item {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }

    .nav-item {
      margin-bottom: -1px;
      text-align: center;
    }

    .nav-link {
      display: block;
      padding: 0.5rem 0rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 100%;
    }

    @include media-breakpoint-up(lg) {
      .nav-link {
        display: block;
        padding: 0.5rem 1rem;
      }
    }

    .nav-link:focus,
    .nav-link:hover {
      text-decoration: none !important;
    }

    .nav-link.disabled {
      color: $gray;
      pointer-events: none;
      cursor: default;
    }

    .nav-link {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      text-decoration: none !important;
    }

    .nav-link.disabled {
      color: $gray;
    }

    .dropdown-menu {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .account-container,
  .order-container,
  .register-container,
  .checkout-container,
  .register-container,
  .attendee-container {
    margin-top: 6rem;
    padding-top: unset;
    padding-bottom: unset;
  }
}

@include media-breakpoint-up(md) {
  @media (min-height: 35rem) {
    .account-card {
      & > .card-header {
        height: 8.25rem;
      }
      & > .card-body {
        min-height: 24rem;
      }
    }
  }
}

.row.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.row.rounded-bottom {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.online-event .event-img {
  background-color: $gray;
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  height: 165px;
  display: block;
  margin: 1rem auto;
  border-radius: 1rem;
}

.row.order-item:nth-child(even) {
  background-color: $white;
}
.row.order-item:nth-child(odd) {
  background-color: $light;
}

.rounded-top-sm {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.rounded-bottom-sm {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.card-body > .order-item:nth-child(even) {
  background-color: $light;
}
.card-body > .order-item:nth-child(odd) {
  background-color: $white;
}
