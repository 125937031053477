.btn {
  text-transform: uppercase;
}

.btn-primary,
.btn-secondary,
.btn-outline-primary:hover,
.btn-outline-secondary:hover {
  color: white;
}

.btn.btn-link:focus {
  box-shadow: unset;
}
